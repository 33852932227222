#header{
    margin-bottom: 2px;
}

/* #content{
    min-height: 100vh;
} */

#noteAuthDisplay{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#searchTopDisplay{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#searchPublic{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.viewSequenceDetail{
    display: flex;
    flex-direction: column;
    align-items: center;    
}

#viewSequencesSummaryPublicList{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#searchOwner{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#userInfoDisplay{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#authDisplay{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

#introduction{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
}
 
#pitchSequenceParentFrame{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 5px;
}

#pitchSequenceChildFrame{
    position: relative;
    width: 280px;
    height: 400px;
}

#pitchSequenceImage{
    z-index: 0;
    position: relative;
    width: 280px;
    height: 400px;
    border: 1px solid gray;
}

.inputDescription{
    margin: 10px;
}

.situationHeaderFotter{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex:1;
}

.situationInputs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    flex:1;
} 

.textInput{ 
    width: 100%;
}

.required{
    background-color:orangered;
    color: white;
    border-radius: 5px;
    padding: 4px;
}

.optional{
    background-color:dodgerblue;
    color: white;
    border-radius: 5px;
    padding: 4px;
}

.pitcher{
    background-color:orangered;
    color: white;
    border-radius: 5px;
    padding: 4px;
}

.batter{
    background-color:dodgerblue;
    color: white;
    border-radius: 5px;
    padding: 4px;
}

.title{
    background-color:dodgerblue;
    color: white;
    border-radius: 5px;
    padding: 4px;
}

.disclosure{
    background-color:#2E8B57;
    color: white;
    border-radius: 5px;
    padding: 4px;
}


.newRegister{
    background-color:teal;
    font-size: small;
    /* color: black; */
    border-radius: 5px;
    padding: 8px;
}


.amplify-tabs button[aria-selected*="false"]{
    color:grey;
    background-color: #DDDDDD;
}


#introductionSubtitleImg{
    width: 97%;
    max-width: 500px;
    height: auto;
    margin-bottom: 22px;
}

#pitchSequenceImg{
    margin-top: 7px;
    width: 100%;
    max-width: 350px;
    height: auto;
}

#introductionButtonImg{
    margin-top: 7px;
    width: 210px;
    height: 65px;
}

#introductionEveryonesNoteButtom{
    margin-top: 7px;
    width: 210px;
    height: 65px;
}

#tanaka_0107_image {
    margin-top: 7px;
    width: 260px;
    height: 550px;
}

#bant_0129_image {
    margin-top: 7px;
    width: 260px;
    height: 550px;
}

.youtube {
    width: 90%;
    aspect-ratio: 16 / 9;
    max-width: 560px;
    max-height: 315px;
}
.youtube iframe {
    width: 100%;
    height: 100%;
}

.policy {
    width: 90%;
    aspect-ratio: 16 / 9;
    max-width: 560px;
    max-height: 315px;
}
.policy iframe {
    width: 100%;
    height: 100%;
}

/* .courseMap button[class*="MuiButtonBase-root-MuiButton-root"]{
    border-radius: 0;
} */

.courseMap button[class*="MuiButton-root"]{
    border-radius: 0;
}

/* .MuiContainer-root{
    width: initial;
} */

.score-board {
    font-size: 8px;
}